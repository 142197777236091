


import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { BASE_API_URL } from "../../../config";
import CommonMixin from "@/mixins/common.mixin";
import Axios from "axios";
import Moment from "moment";
import Datepicker from "vuejs-datepicker";
import DisplayFiles from "@/views/DisplayDocument.vue";

@Component({
  components: {
    Datepicker,
    DisplayFiles,
  },
})
export default class Communications extends mixins(CommonMixin) {
  public communications = [];
  public filter = {
    from: '',
    to: '',
    loanTxnId: '',
    dateTimeRangeFrom: Moment().subtract('7', 'days').toDate(),
    dateTimeRangeTo: Moment().toDate(),
  };
  public playingAudioIndex = null;
  public finalPlayingAudioIndex = null;

  public async fetchCommunications() {
    try {
      let response = await Axios.get(BASE_API_URL + "communication/getAllCommunicationHistory", {
        params: this.filter,
      });

      this.communications = response.data.communications;
    } catch (error) {
      console.log(error);
    }
  }

  public async playAudio(index) {
    if (this.playingAudioIndex !== null) {
      this.stopPlayingAudio(this.playingAudioIndex);
    }

    this.playingAudioIndex = index;
  }

  public stopPlayingAudio(index) {
    if (index !== null) {
      let audio = this.$refs["audio" + index][0];
      audio.pause();
    }
  }

  public pausePlayingAudio() {
    this.playingAudioIndex = null;
  }

  public displaySmsDetails(data) {
    if (data.body && data.attachments.length > 0) {
      this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
        data.body,
        data.attachments
      );
    } else {
      this.$refs.dfl["setInitialValuesForShowMessage"](data.body, null);
    }
  }

  async mounted() {
    await this.fetchCommunications();
  }
}
